import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/Context';
import { ACTION, GENERIC_MODAL_ACTIONS } from '../../context/actionTypes';
import api from '../../utils/api';
import { setBottomBarError } from '../../utils/Functions';

interface Filter {
  maxSize: number;
  minSize: number;
}
export default function useDocuments() {
  const [_, dispatch] = useAppContext();
  const [documents, setDocuments] = useState([]); //will contain all documents from the database of a company
  const [search, setSearch] = useState('');
  const [moreFilters, setMoreFilters] = useState(false);
  const [filters, setFilters] = useState<Filter>({ maxSize: 0, minSize: 0 });

  const [displayedDocuments, setdisplayedDocuments] = useState([]); //will contain all documents from the database of a company at first, then it will changed based on the searchbarQuery

  const [{ user }] = useAppContext();

  useEffect(() => {
    loadDocuments();
    // document.getElementById("dropdownSearch").style.display = "none";
  }, []);

  const loadDocuments = async () => {
    try {
      const response = await api.get(`/document/list/${user.company_id}`);
      setDocuments(response.data);
      setdisplayedDocuments(response.data);
    } catch (error) {
      console.error('Failed to load documents', error);
      setBottomBarError(dispatch, error);
    }
  };

  const createDocument = async () => {
    dispatch({
      type: ACTION.SET_GENERIC_MODAL,
      payload: {
        props: { reload: loadDocuments },
        action: GENERIC_MODAL_ACTIONS.UPLOAD_DOCUMENT,
      },
    });
  };
  //searchbarQuery
  const searchName = (e) => {
    const docstoShow = [];
    documents.forEach((element) => {
      var name = e.target.value.toLowerCase();
      var doctoCheck = element.name.toLowerCase().replace(/\.[^/.]+$/, '');
      if (doctoCheck.includes(name)) {
        docstoShow.push(element);
      }
    });
    setdisplayedDocuments(docstoShow);
  };
  //shows filter menu
  const dropHiddenMenu = () => {
    if (document.getElementById('dropdownSearch').style.display == 'none')
      document.getElementById('dropdownSearch').style.display = 'block';
    else document.getElementById('dropdownSearch').style.display = 'none';
  };
  //hdies filter menu
  const hideDropMenu = () => {
    document.getElementById('dropdownSearch').style.display = 'none';
  };

  return [
    {
      documents,
      search,
      moreFilters,
      filters,
      displayedDocuments,
    },
    {
      createDocument,
      loadDocuments,
      setSearch,
      setMoreFilters,
      setFilters,
      dispatch,
      searchName,
      setdisplayedDocuments,
      dropHiddenMenu,
      hideDropMenu,
    },
  ];
}
