import { useEffect, useState } from 'react';
import api from '../../utils/api';
import { ACTION, GENERIC_MODAL_ACTIONS } from '../../context/actionTypes';
import { useAppContext } from '../../context/Context';
import { setBottomBarError } from '../../utils/Functions';

export default function useCompanies() {
  const [_, dispatch] = useAppContext();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = async () => {
    try {
      const response = await api.get('/company/all');
      var responseObject = response.data;
      // A tester avant de mettre en dev et prod
      //   var i = 0;
      //   responseObject.forEach((element: any) => {
      //     api
      //       .get(`/company/${element.id}/countUsage`)
      //       .then((e) => {
      //         element.int_size = e.data;
      //       })
      //       .then(() => {
      //         if (i == responseObject.length) {
      //           setCompanies(responseObject);
      //         }
      //       })
      //       .catch((error) => console.error(error));
      //     i++;
      //   });
      setCompanies(responseObject);
    } catch (error) {
      console.error('Failed to load companies', error);
      setBottomBarError(dispatch, error);
    }
  };

  const createCompany = async () => {
    dispatch({
      type: ACTION.SET_GENERIC_MODAL,
      payload: {
        props: { reload: loadCompanies },
        action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_COMPANY,
      },
    });
  };

  return [{ companies }, { loadCompanies, createCompany }];
}
