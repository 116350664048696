import React, { useMemo } from 'react';

interface DistanceMatchComponentProps {
  distance: number | undefined;
  maxDistance?: number;
  minDistance?: number;
}

const DistanceMatchComponent: React.FC<DistanceMatchComponentProps> = ({
  distance,
  maxDistance = 32,
  minDistance = 0
}) => {
  // Fonction pour normaliser la distance entre 0 et 1
  const normalizeDistance = (distance: number, maxDistance: number, minDistance: number) => {
    return (distance - minDistance) / (maxDistance - minDistance);
  };

  const matchPercentage = useMemo(() => {
    if (distance === undefined || distance === null) {
      return 'N/A';
    }
    const clampedDistance = Math.max(minDistance, Math.min(distance, maxDistance));
    const normalizedDistance = normalizeDistance(clampedDistance, maxDistance, minDistance);
    // Convertir la distance normalisée en pourcentage de correspondance
    // Une distance normalisée de 0 correspond à 100%, et 1 correspond à 0%
    const percentage = (1 - normalizedDistance) * 100;
    return percentage.toFixed(2);
  }, [distance, maxDistance, minDistance]);

  const getColorClass = (percentage: number) => {
    if (percentage >= 98) return 'bg-green-700';
    if (percentage >= 97) return 'bg-green-500';
    if (percentage >= 80) return 'bg-green-400';
    if (percentage >= 60) return 'bg-yellow-500';
    if (percentage >= 40) return 'bg-orange-500';
    return 'bg-perso-chartRed';
  };

  return (
      <>
        <span
            className={`inline-block px-2 py-1 text-xs font-semibold text-white rounded-full ${
                matchPercentage !== 'N/A' ? getColorClass(parseFloat(matchPercentage)) : 'bg-gray-500'
            }`}
            data-tooltip-id="doc-tooltip"
            data-tooltip-content={`Le pourcentage de correspondance est ${matchPercentage !== 'N/A' ? `${matchPercentage}%` : 'indisponible'}`}
        >
      Match : {matchPercentage !== 'N/A' ? `${matchPercentage}%` : matchPercentage}
    </span>
        {/* TODO IN DEV*/}
        {/* <span  className={`inline-block px-2 py-1 text-xs font-semibold text-white rounded-full ${*/}
        {/*        matchPercentage !== 'N/A' ? getColorClass(parseFloat(matchPercentage)) : 'bg-gray-500'*/}
        {/*    }`}>*/}
        {/*  {distance}*/}
        {/*</span>*/}
      </>

  );
};

export default DistanceMatchComponent;