import { useAppContext } from "../../context/Context"
import { ACTION } from "../../context/actionTypes"
import { setBottomBarError } from "../../utils/Functions"
import api from "../../utils/api"

export default function useDeleteCompany(props: any) {
    const [_, dispatch] = useAppContext()
    const { id, reload } = props
    const deleteCompany = async () => {
        try {

            await api.delete(`/company/realdelete-${id}`)
            await reload()
            toggleMe()
        }
        catch (err) {
            console.error("Failed to delete company", err);
            setBottomBarError(dispatch, err)
        }

    }

    const toggleMe = () => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null })

    return [{}, { deleteCompany, toggleMe }]
}
