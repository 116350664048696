import { MdOutlineLoop } from "react-icons/md"
import { MESSAGE_TYPE } from "../../context/actionTypes"
import MarkDownFormater from "./MarkDownFormater"
import { IoCopyOutline } from "react-icons/io5"
import { VscFeedback } from "react-icons/vsc"
import { FaArrowRight } from "react-icons/fa"
import { setBottomBarSucces } from "../../utils/Functions"
import { twMerge } from "tailwind-merge"
import { useEffect, useRef, useState } from "react"
import DistanceMatchComponent from "./DistanceMatchComponent";

export default function MessagesDisplayer({ messages, createFeedback, dispatch, handleRetry, selectedMessage, setSelectedMessage, isLoading, bottomRef, displayRag }) {
    messages?.length <= 0 && messages.push({ message: `Bonjour et bienvenue sur Virteem Companion ! Je suis un assistant conçu pour vous aider à trouver des informations précises dans notre base de connaissances. Posez-moi des questions spécifiques sur notre domaine d'expertise, et je ferai de mon mieux pour vous fournir une réponse pertinente basée sur les documents disponibles. Si vous avez des questions générales ou souhaitez simplement discuter, je risque de ne pas être très bavard. Essayez de poser une question précise pour obtenir des résultats optimaux. Merci !`, type: MESSAGE_TYPE.MACHINE })



    return (
        <div className='w-full lg:w-3/4 mx-auto pt-12 lg:pt-0 '>
            {messages.map((message, index) => (
                message?.type && (
                    <div className=" text-gray-800 pt-4">
                        <div className='flex items-start gap-2 pb-3'>
                            <div className='pt-3'>
                                {message?.type === MESSAGE_TYPE.HUMAN ? (
                                    <div className=" w-6 h-6">
                                        <img src="/user.svg" alt="user" className='w-full flex-shrink-0' />
                                    </div>
                                ) : (
                                    <div className=" w-6 h-6">
                                        <img src="/chatbot-black.svg" alt="user" className='w-full flex-shrink-0' />
                                    </div>
                                )}
                            </div>
                            <div key={index} className={`relative flex ${message.type === MESSAGE_TYPE.HUMAN ? 'justify-end' : 'justify-start'} gap-2 text-base w-full`}>
                                <div onClick={() => {
                                    message.type === MESSAGE_TYPE.MACHINE && setSelectedMessage(message)
                                }} className={twMerge(` relative ${message.type === MESSAGE_TYPE.HUMAN ? ' bg-perso-purple' : ' bg-white cursor-pointer '} p-4 font-light rounded-r-xl rounded-bl-xl shadow-lg transform duration-500 w-full`)}>

                                    <MarkDownFormater>
                                        {message.message}
                                    </MarkDownFormater>
                                    <div className="w-full ">
                                        {selectedMessage?.message === message?.message && (
                                            <div className='text-sm space-y-2 pt-4'>
                                                {selectedMessage?.rag?.map((rag, key) => (
                                                    <div className="flex gap-x-8">
                                                        <div
                                                            onClick={() => displayRag(rag)}
                                                            key={key}
                                                            data-tooltip-id="doc-tooltip"
                                                            data-tooltip-content={`${rag.source} - Page ${rag.page}`}
                                                            className='cursor-pointer flex justify-start gap-1 items-center text-xs font-semibold'
                                                        >
                                                            <FaArrowRight className='h-3 w-3' />
                                                            <div className=' underline '>{`${rag.source} - Page ${rag.page}`}</div>

                                                        </div>
                                                         <DistanceMatchComponent distance={rag.distance} maxDistance={32}/>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className=" flex gap-3 justify-end pb-3">
                            {
                                message.type === MESSAGE_TYPE.MACHINE && index > 0 && (
                                    <>

                                        <IoCopyOutline
                                            data-tooltip-id="chat-tooltip"
                                            data-tooltip-content={"Copier"}
                                            className=' w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none '
                                            onClick={() => {
                                                navigator.clipboard.writeText(message.message)
                                                setBottomBarSucces(dispatch, 'Message copié')
                                            }}
                                        />
                                        <MdOutlineLoop
                                            data-tooltip-id="chat-tooltip"
                                            data-tooltip-content={"Relancer"}
                                            className=' w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none'
                                            onClick={() => handleRetry(index - 1)}
                                        />
                                        <VscFeedback
                                            data-tooltip-id="chat-tooltip"
                                            data-tooltip-content={"Laisser un avis"}
                                            className=' w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none'
                                            onClick={() => createFeedback(messages[index - 1], message)}
                                        />
                                    </>
                                )
                            }
                        </div>
                    </div>
                )

            ))}
            {isLoading && (
                <div className='flex items-center gap-2'>
                    <div className=" w-6 h-6">
                        <img src="/chatbot-black.svg" alt="user" className='w-full flex-shrink-0' />
                    </div>
                    <div className=' inline-block'>
                        <div className='bg-gray-500 p-2 rounded-lg flex gap-1 text-xl animate-pulse'>
                            <div className=''>•</div>
                            <div className=''>•</div>
                            <div className=''>•</div>
                        </div>
                    </div>
                </div>
            )}

            <div ref={bottomRef} />
        </div>
    )
}